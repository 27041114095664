<template>
    <div>
        <div v-if="!loading">
            <h1 class="mt-2 mb-4">{{trans('cdw-health-and-safety-checklist',228)}}</h1>

            <b-alert variant="success" :show="success_dismiss_count_down" @dismiss-count-down="countDownChangedSuccess" fade >
                <div>
                    <b-icon icon="check-circle-fill" font-scale="1" class="mr-3"></b-icon>
                    <span class="align-middle">{{trans('successfully-saved',253)}}</span>
                </div>
                
            </b-alert>

            <b-alert variant="danger" :show="unsuccess_dismiss_count_down" @dismiss-count-down="countDownChangedUnsuccess" fade >
                <div>
                    <b-icon icon="x-circle-fill" font-scale="1" class="mr-3"></b-icon>
                    <span class="align-middle">{{trans('unsuccessfull-save',253)}}</span>
                </div>
                
            </b-alert>

            <b-overlay :show="loading" :opacity="0.0">

                <b-card class="mb-3">

                    <div class="app-card-profile-title mb-3">{{trans('cdw-registration-general',238)}}</div>

                    <b-alert variant="warning" show class="mb-3 pb-1">
                        <div class="text-md-start mb-2">
                                    Deze arbochecklist is op alle zorgfuncties (niveau 1 tot en met niveau 6) bij de opdrachtgever van toepassing.
                                    De opdrachtgever is bekend met de wettelijke verplichting om:
                        </div>
                        <ul class="mb-2">
                            <li><div class="text-small mb-1">De uitzendkracht voor aanvang van het werk voor te lichten over de risico’s en de te nemen maatregelen;</div></li>
                            <li><div class="text-small mb-1"> De uitzendkracht voor aanvang van het werk de benodigde persoonlijke beschermingsmiddelen (PBM) te verstrekken;</div></li>
                            <li><div class="text-small mb-1">De uitzendkracht in te werken;</div></li>
                            <li><div class="text-small mb-1">Leiding te geven aan de uitzendkracht en toezicht te houden op de werkplek.</div></li>
                        </ul>
                        <div class="text-md-start mb-2">De gedetailleerde voorschriften staan in (maar niet voorbehouden tot) Arbowet (artikel 1, lid 1) (artikel 3) (artikel 5, lid 5) (artikel 8) (artikel 11).</div>
                    </b-alert> 

                    <div class="app-card-profile-title mb-3">{{trans('cdw-health-and-safety-medical-staff',238)}}</div>

                    <!-- check 1 -->
                    <app-row-left-label :slots="[9, 3]" :isMarginBetween="true">
                        <template v-slot:col-1>
                            <label class="app-input-top-label font-weight-normal">{{trans('cdw-possession-of-valid-big',238)}}</label>
                        </template>
                        <template v-slot:col-2>
                            <app-radio-group class="float-right" v-model="cItemData.question_1" :options="answers" validatorRules="required" :validatorCustomMessage="{ required: trans('required-field',256) }" />
                        </template>
                    </app-row-left-label>

                    <hr class="mt-1 mb-2" />

                    <!-- check 2-->
                    <app-row-left-label :isMarginBetween="true" class="mt-2">
                        <label class="app-input-top-label font-weight-normal">{{trans('cdw-waadi-point-two-additional',238)}}</label>
                        <div class="ml-5 mt-3">
                            <app-check-box class="mb-3" v-model="cItemData.question_2.q1.value">{{trans('cdw-waadi-point-two-additional-a1',238)}} </app-check-box> 
                            <app-check-box class="mb-3" v-model="cItemData.question_2.q2.value">{{trans('cdw-waadi-point-two-additional-a2',238)}} </app-check-box> 
                            <app-check-box class="mb-3" v-model="cItemData.question_2.q3.value">{{trans('cdw-waadi-point-two-additional-a3',238)}} </app-check-box>
                            <app-check-box class="mb-3" v-model="cItemData.question_2.q4.value">{{trans('cdw-waadi-point-two-additional-a4',238)}} </app-check-box> 
                            <app-row-left-label :slots="[3, 9]">
                                <template v-slot:col-1>
                                    <app-check-box  class="mb-3" v-model="cItemData.question_2.q5.value">{{trans('cdw-waadi-point-two-additional-a5',238)}}  </app-check-box> 
                                </template>
                                <template v-slot:col-2>
                                    <app-memo rows="3" class="mt-1 mb-3" v-if="cItemData.question_2.q5.value == 1" v-model="cItemData.question_2.q5.text"/>
                                </template>
                            </app-row-left-label>
                        </div>
                        <small class="ml-5">{{trans('cdw-waadi-point-two-additional-a6',238)}} </small> 
                        <app-memo class="ml-5" rows="3" v-model="cItemData.question_2.q6.text"/>
                    </app-row-left-label>

                    <hr class="mt-1 mb-3" />
                
                    <!-- check 3 -->
                    <app-row-left-label :isMarginBetween="true" class="mt-2">
                            <label class="app-input-top-label font-weight-normal">{{trans('cdw-waadi-point-3',238)}}</label>
                            <div class="ml-5  mt-3">
                                <app-row-left-label :slots="[5, 7]" :isMarginBetween="true" class="app-local-min-height">
                                    <template v-slot:col-1>
                                        <app-check-box v-model="cItemData.question_3.q1.value">{{trans('cdw-waadi-point-3-gloves',238)}} </app-check-box> 
                                    </template>
                                    <template v-slot:col-2 >
                                        <div class="d-flex flex-row" :class="{'justify-content-end': !is_mobile.documents}" v-if="cItemData.question_3.q1.value == 1">
                                            <span class="app-input-top-label font-weight-normal">{{trans('cdw-waadi-point-3-client',238)}}</span>
                                            <app-radio-group v-model="cItemData.question_3.q1.option" class="ml-4" :options="answers" validatorRules="required"  :validatorCustomMessage="{ required: trans('required-field',256) }" />
                                        </div>
                                    </template>
                                </app-row-left-label>

                                <app-row-left-label :slots="[5, 7]"  :isMarginBetween="true" class="app-local-min-height">
                                    <template v-slot:col-1>
                                        <app-check-box v-model="cItemData.question_3.q2.value">{{trans('cdw-waadi-point-3-mask',238)}} </app-check-box>
                                    </template>
                                    <template v-slot:col-2>
                                        <div class="d-flex flex-row" :class="{'justify-content-end': !is_mobile.documents}" v-if="cItemData.question_3.q2.value == 1">
                                            <span class="app-input-top-label font-weight-normal">{{trans('cdw-waadi-point-3-client',238)}}</span>
                                            <app-radio-group v-model="cItemData.question_3.q2.option" class="ml-4" :options="answers" validatorRules="required"  :validatorCustomMessage="{ required: trans('required-field',256) }" />
                                        </div>
                                    </template>
                                </app-row-left-label>

                                <app-row-left-label :slots="[5, 7]" :isMarginBetween="true" class="app-local-min-height">
                                    <template v-slot:col-1>
                                        <app-check-box v-model="cItemData.question_3.q3.value">{{trans('cdw-waadi-point-3-shoes',238)}} </app-check-box> 
                                    </template>
                                    <template v-slot:col-2>
                                        <div class="d-flex flex-row" :class="{'justify-content-end': !is_mobile.documents}" v-if="cItemData.question_3.q3.value == 1">
                                            <span class="app-input-top-label font-weight-normal">{{trans('cdw-waadi-point-3-client',238)}}</span>
                                            <app-radio-group v-model="cItemData.question_3.q3.option" class="ml-4" :options="answers" validatorRules="required"  :validatorCustomMessage="{ required: trans('required-field',256) }" />
                                        </div>
                                    </template>
                                </app-row-left-label>

                                <app-row-left-label :slots="[5, 7]" :isMarginBetween="true" class="app-local-min-height">
                                    <template v-slot:col-1>
                                        <app-check-box v-model="cItemData.question_3.q4.value">{{trans('cdw-waadi-point-3-protect',238)}} </app-check-box> 
                                    </template>
                                    <template v-slot:col-2>
                                        <div class="d-flex flex-row" :class="{'justify-content-end': !is_mobile.documents}" v-if="cItemData.question_3.q4.value == 1">
                                            <span class="app-input-top-label font-weight-normal">{{trans('cdw-waadi-point-3-client',238)}}</span>
                                            <app-radio-group v-model="cItemData.question_3.q4.option" class="ml-4" :options="answers" validatorRules="required"  :validatorCustomMessage="{ required: trans('required-field',256) }" />
                                        </div>
                                    </template>
                                </app-row-left-label>

                                <app-row-left-label :slots="[5, 7]" :isMarginBetween="true" class="app-local-min-height">
                                    <template v-slot:col-1>
                                        <app-check-box v-model="cItemData.question_3.q5.value">{{trans('cdw-waadi-point-3-other',238)}} </app-check-box> 
                                    </template>
                                    <template v-slot:col-2>
                                        <div class="d-flex flex-row" :class="{'justify-content-end': !is_mobile.documents}" v-if="cItemData.question_3.q5.value == 1">
                                            <span class="app-input-top-label font-weight-normal">{{trans('cdw-waadi-point-3-client',238)}}</span>
                                            <app-radio-group v-model="cItemData.question_3.q5.option" class="ml-4" :options="answers" validatorRules="required"  :validatorCustomMessage="{ required: trans('required-field',256) }" />
                                        </div>
                                    </template>
                                </app-row-left-label>

                                <app-memo  v-if="cItemData.question_3.q5.value == 1" v-model="cItemData.question_3.q5.text" />

                                <small class="text-muted" > {{trans('cdw-waadi-point-3-latex',238)}} </small>
                            </div>
                    </app-row-left-label>
                    <hr class="mt-1 mb-2" />

                    <!-- check 4-->
                    <app-row-left-label :slots="[9, 3]" :isMarginBetween="true" class="mt-2">
                        <template v-slot:col-1>
                            <label class="app-input-top-label font-weight-normal">{{trans('cdw-exposed-to-biological-agents',238)}}</label>
                        </template>
                        <template v-slot:col-2>
                            <app-radio-group class="float-right" v-model="cItemData.question_4" :options="answers" validatorRules="required" :validatorCustomMessage="{ required: trans('required-field',256) }" />
                        </template>
                    </app-row-left-label>

                    <hr class="mt-1 mb-2" />

                    <!--check 5 -->
                    <app-row-left-label :slots="[9, 3]" :isMarginBetween="true" class="mt-2">
                        <template v-slot:col-1>
                            <label class="app-input-top-label font-weight-normal">{{trans('cdw-work-that-requires-vaccination',238)}}</label>
                        </template>
                        <template v-slot:col-2>
                            <app-radio-group class="float-right" v-model="cItemData.question_5" :options="answers" validatorRules="required" :validatorCustomMessage="{ required: trans('required-field',256) }" />
                        </template>
                    </app-row-left-label>

                    <hr class="mt-1 mb-2" />

                    <!-- check 6 -->
                    <app-row-left-label :slots="[9, 3]" :isMarginBetween="true" class="mt-2">
                        <template v-slot:col-1>
                            <label class="app-input-top-label font-weight-normal">{{trans('cdw-required-vaccination-from-client',238)}}</label>
                        </template>
                        <template v-slot:col-2>
                            <app-radio-group class="float-right" v-model="cItemData.question_6" :options="answers" validatorRules="required" :validatorCustomMessage="{ required: trans('required-field',256) }" />
                        </template>
                    </app-row-left-label>

                    <hr class="mt-1 mb-2" />

                    <!-- check 7 -->
                    <app-row-left-label :slots="[9, 3]" :isMarginBetween="true" class="mt-2">
                        <template v-slot:col-1>
                            <label class="app-input-top-label font-weight-normal">{{trans('cdw-exposed-to-cytostatics',238)}}</label>
                        </template>
                        <template v-slot:col-2>
                            <app-radio-group class="float-right" v-model="cItemData.question_7" :options="answers"  validatorRules="required" :validatorCustomMessage="{ required: trans('required-field',256) }" />
                        </template>
                    </app-row-left-label>

                    <hr class="mt-1 mb-2" />

                     <!-- check 8 -->
                    <app-row-left-label :slots="[9, 3]" :isMarginBetween="true" class="mt-2">
                        <template v-slot:col-1>
                            <label class="app-input-top-label font-weight-normal">{{trans('cdw-waadi-point-5-additional',238)}}</label>
                        </template>
                        <template v-slot:col-2>
                            <app-radio-group class="float-right" v-model="cItemData.question_8" :options="answers"  validatorRules="required" :validatorCustomMessage="{ required: trans('field-required-error',180) }" />
                        </template>
                    </app-row-left-label>

                    <hr class="mt-1 mb-2" />

                    <!-- check 9 -->
                    <app-row-left-label :slots="[9, 3]" :isMarginBetween="true" class="mt-2">
                        <template v-slot:col-1>
                            <label class="app-input-top-label font-weight-normal">{{trans('cdw-deal-with-moments',238)}}</label>
                        </template>
                        <template v-slot:col-2>
                            <app-radio-group class="float-right" v-model="cItemData.question_9" :options="answers" validatorRules="required" :validatorCustomMessage="{ required: trans('required-field',256) }" />
                        </template>
                    </app-row-left-label>

                    <hr class="mt-1 mb-2" />

                    <!-- check 10 -->
                    <app-row-left-label :slots="[9, 3]" :isMarginBetween="true" class="mt-2">
                        <template v-slot:col-1>
                            <label class="app-input-top-label font-weight-normal">{{trans('cdw-other-risks',238)}}</label>
                        </template>
                        <template v-slot:col-2>
                            <app-radio-group class="float-right" v-model="cItemData.question_10" :options="answers" validatorRules="required" :validatorCustomMessage="{ required: trans('required-field',256) }" />
                        </template>
                    </app-row-left-label>

                    <!-- TextArea for check 10 -->
                    <app-row-left-label :slots="[3, 9]" :isMarginBetween="true" class="mt-2" v-if="itemData.question_10 == 1">
                        <template v-slot:col-1>
                            <label class="app-input-top-label font-weight-normal">{{trans('cdw-other-risks-description',238)}}</label>
                        </template>
                        <template v-slot:col-2>
                            <app-memo id="textarea-default" rows="3" v-model="cItemData.risk_description"/>  
                        </template>
                    </app-row-left-label>
                </b-card>

                <b-card class="mb-3">

                    <app-row-left-label :slots="[6,6]" :isMarginBetween="false">
                        <template v-slot:col-1>
                            <app-input v-model="cItemData.completed" :label="trans('cdw-checklist-completed-by',238)" mask="lettersE8-30" validatorRules="required" validatorName="Arbo checklist ingevuld door" />
                        </template>

                        <template v-slot:col-2>
                            <app-input v-model="cItemData.functions" :label="trans('function',253)" validatorRules="required" validatorName="Functie" mask="lettersE4-30" />
                        </template>
                    </app-row-left-label>

                    <app-row-left-label class="mt-2">
                        <app-check-box v-model="cItemData.confirm_checklist" :rightLabel="trans('cdw-checklist-completed-truthfully',238)" validatorRules="required" validatorName="Toestemming" :validatorCustomMessage="{ required: trans('cdw-consent-is-required',228) }" />
                    </app-row-left-label> 

                </b-card>

                <template #overlay>
                    <div></div>
                </template>
            </b-overlay>
        </div>
        <b-row v-if="loading">
            <app-loader />
        </b-row>
                
    </div>
</template>

<script>

import AppLoader from '@core/components/AppLoader.vue';

export default {
    components: {
        AppLoader
    },
    props: ["itemData", "showSuccessAlert", "showUnsuccessAlert", "loading"],
        
    data(){
        return{
            dismiss_secs: 3,
            success_dismiss_count_down: 0,
            unsuccess_dismiss_count_down: 0,
            answers: [
                { value: 1, text: this.trans('yes-option',253) },
                { value: 0, text: this.trans('no-option',253)},
            ],
        }
    },

    watch:{
        showSuccessAlert(val){
            this.success_dismiss_count_down = this.dismiss_secs;
        },

        showUnsuccessAlert(){
            this.unsuccess_dismiss_count_down = this.dismiss_secs;
        },

        "cItemData.question_7": {
            handler: function(val) {
                if (val == 0) {
                    this.cItemData.risk_description = '';
                }
            },
        },
    },

    methods:{
        countDownChangedSuccess(success_dismiss_count_down) {
            this.success_dismiss_count_down = success_dismiss_count_down;
        },

        countDownChangedUnsuccess(unsuccess_dismiss_count_down){
            this.unsuccess_dismiss_count_down = unsuccess_dismiss_count_down;
        }
    },
    
    computed: {
        cItemData: {
            get() { return this.itemData},
            set() {}
        }
    }
};
</script>
<style scoped>
.app-local-min-height{
    min-height: 40.4px;
}
</style>

