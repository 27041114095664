<template>
    <div>
        <div v-if="!loading">
            <h1 class="mt-2 mb-4">Organisatie <span>informatie</span></h1>

            <b-alert variant="success" :show="success_dismiss_count_down" @dismiss-count-down="countDownChangedSuccess" fade >
                <div>
                        <b-icon icon="check-circle-fill" font-scale="1" class="mr-3"></b-icon>
                        <span class="align-middle">{{trans('successfully-saved',253)}}</span>
                </div>
                
            </b-alert>

            <b-alert variant="danger" :show="unsuccess_dismiss_count_down" @dismiss-count-down="countDownChangedUnsuccess" fade >
                <div>
                        <b-icon icon="x-circle-fill" font-scale="1" class="mr-3"></b-icon>
                        <span class="align-middle">{{trans('unsuccessfull-save',253)}}</span>
                </div>
                
            </b-alert>

            <b-overlay :show="loading" :opacity="0.0">

                <b-card class="mb-3">
                    <div class="app-card-profile-title mb-3">{{trans('cdw-general-data',236)}}</div>
                    <clients-data-wizard-organization-general-component :idItem="cItemData.client_data.id_client_data_main" :kvk.sync="cItemData.kvk" :clientName.sync="cItemData.client_data.client_name" :itemData="itemData" />
                </b-card>

                <b-card class="mb-3">
                    <div class="app-card-profile-title mb-3">{{trans('cdw-address-data',228)}}</div>
                    <app-wizard-address-nl :isMarginBetween="false" :address="cItemData.address"/>
                </b-card>

                <b-card class="mb-3">
                    <div class="app-card-profile-title mb-3">{{trans('cdw-financial-data',236)}}</div>

                    <app-row-left-label :slots="[6,6]" :isMarginBetween="false">
                        <template v-slot:col-1>
                            <label class="app-input-top-label">{{trans('cdw-hours-on-invoice-per',236)}}</label>
                            <app-select v-model="cItemData.client_data.hours_on_invoice" reduceValue :options="invoice_hours_options" />
                        </template>
                        <template v-slot:col-2>
                            <label class="app-input-top-label">{{trans('cdw-invoice-frequency',236)}}</label>
                            <validation-provider v-slot="{ errors}" vid="frequency_type" ref="myinput" rules="required" name="Factuur frequentie" :custom-messages="{ required: trans('required-field',256) }">
                                <app-select v-model="cItemData.client_data.id_frequency_const_type" :disable="true" reduceValue :options="frequency" />
                                <small class="text-danger">{{errors[0]}}</small> 
                            </validation-provider>
                        </template>
                    </app-row-left-label>

                    <app-row-left-label :slots="[6, 6]" :isMarginBetween="false">
                        <template v-slot:col-1>
                            <app-email :email.sync="cItemData.client_data.invoice_email" validatorName="Factuur email adres" validatorRules="required" :label="trans('cdw-invoice-email-address',236)" type="client" customSpinnerClass="app-local-input-spinner"/>
                        </template>
                        <template v-slot:col-2>
                            <label class="app-input-top-label">{{trans('cdw-split-invoice-by',236)}}</label>
                            <b-row class="m-1">
                                <app-check-box id="ch_cost_place" v-model="cItemData.client_data.cost_place" class="custom-control-primary mr-3" value="1" unchecked-value="0" size="lg" :rightLabel="trans('cdw-cost-centre',236)" />
                                <app-check-box id="ch_flexworker" v-model="cItemData.client_data.flexworker" class="custom-control-primary mr-3" value="1" unchecked-value="0" size="lg" :rightLabel="trans('flexworker',253)" />
                                <app-check-box id="ch_week" v-model="cItemData.client_data.week" class="custom-control-primary mr-3" :disabled="cItemData.client_data.hours_on_invoice == 1" value="1" unchecked-value="0" size="lg" :rightLabel="trans('week',253)" />
                                <app-check-box id="ch_invoice_split_purchasenumber" v-model="cItemData.client_data.invoice_split_purchasenumber" class="custom-control-primary mr-4" value="1" unchecked-value="0" size="lg" :rightLabel="trans('cdw-invoice-split-purchasenumber',236)" />
                            </b-row>
                        </template>
                    </app-row-left-label>
                    <app-row-left-label :slots="[6, 6]" :isMarginBetween="false">
                    <template v-slot:col-1>
                        <app-email :email.sync="cItemData.client_data.email" validatorName="Organisation e-mail" validatorRules="required" :label="trans('cdw-organisation-email-address',236)" type="person" customSpinnerClass="app-local-input-spinner"/>
                    </template>
                </app-row-left-label>
                    <div class="mb-3">
                        <small class="text-muted"> {{trans('cdw-standard-payment-information',236)}}</small>
                    </div>

                </b-card>
                

                <template #overlay>
                    <div></div>
                </template>
            </b-overlay>

                <!--additionalInfo="HappyNurse hanteert een standaard betaaltermijn van 14 dagen."-->
                <!--additionalInfo="Landcode NL, 9 cijfers, letter B en een controle getal van 2 cijfers."-->
        </div>
        <b-row v-if="loading">
            <app-loader />
        </b-row>
    </div>
</template>

<script>

import { ValidationObserver } from "vee-validate";
import { btwnumber } from "@validations";
import AppLoader from '@core/components/AppLoader.vue';
import AppWizardAddressNl from '@core/components/Address/AppWizardAddressNl.vue';
import AppEmail from '@core/components/AppEmail.vue';
import ClientsDataWizardOrganizationGeneralComponent from './Components/ClientsDataWizardOrganizationGeneralComponent.vue';


export default {
    components: {
        AppWizardAddressNl,
        AppLoader,
        AppEmail,
        ClientsDataWizardOrganizationGeneralComponent
    },
    props: ["itemData", "showSuccessAlert", "showUnsuccessAlert", "loading"],
    
    mounted(){
        //tymczasowo
        this.cItemData.client_data.id_frequency_const_type = 1;
        if(this.cItemData.client_data.hours_on_invoice == 1) {
            this.cItemData.client_data.week = 0;
        }
    },

    watch:{
        showSuccessAlert(val){
            this.success_dismiss_count_down = this.dismiss_secs;
        },

        showUnsuccessAlert(){
            this.unsuccess_dismiss_count_down = this.dismiss_secs;
        },
        "cItemData.client_data.hours_on_invoice": {
            handler: function(val) {
                if(val == 1) {
                    this.cItemData.client_data.week = 0;
                }
            }
        },
    },

    computed: {
        cItemData: {
            get() { return this.itemData },
            set(value) { this.$emit('update:itemData', value) }, 
        }
    },

    data(){
        return{
            dismiss_secs: 3,
            success_dismiss_count_down: 0,
            unsuccess_dismiss_count_down: 0,

            frequency: [
                { value: 1, name: this.trans('cdw-weekly',236) },
                { value: 2, name: this.trans('cdw-every-four-weeks',236) },
                { value: 3, name: this.trans('cdw-monthly',236) },
            ],

            invoice_hours_options: [
                { value: 0, name: this.trans('day',253) },
                { value: 1, name: this.trans('declaration',253) },
                { value: 2, name: this.trans('week',253) }
            ],
        }
    },

    methods:{
        countDownChangedSuccess(success_dismiss_count_down) {
            this.success_dismiss_count_down = success_dismiss_count_down;
        },

        countDownChangedUnsuccess(unsuccess_dismiss_count_down){
            this.unsuccess_dismiss_count_down = unsuccess_dismiss_count_down;
        }
    }
};
</script>
<style></style>
<style lang="scss">
</style>
