<template> 
    <ValidationObserver ref="observer">
        <b-form>
            <b-row class="display-flex align-items-end">

                <b-col md="6" xl="6">
                    <app-row-left-label>
                        <app-input v-model="cClientName" validatorName="Organisatienaam" :label="trans('cdw-organization-name',236)" validatorRules="required" mask="lettersE10-40"/>
                    </app-row-left-label>
                    <app-row-left-label>
                        <app-input v-model="cKvk.kvk_number" type="integer" validatorName="KVK nummer" :key="refresh" :label="trans('cdw-enter-kvk',236)" validatorRules="required|digits:8" mask="########" @input="changingKvk()" v-on:blur="checkKvk()" :disabled="cItemData.client_data.kvk_organization == '1'"/>
                    </app-row-left-label>
                </b-col>

                <b-col md="6" xl="6">
                    
                    <app-row-left-label v-if="loading || status == 1" >            
                        <b-card no-body class="p-2 app-local-card-min-height app-card-info">
                            <div class="app-info-card-label" v-if="loading">
                                <b-spinner class="app-local-spinner"/>{{trans('checking',253)}}
                            </div>
                            <div class="app-info-card-label" v-if="!loading && status === 1">
                                <label class="flabel">{{trans('kvk-data',257)}}</label>
                                <div> {{ checked_kvk_trade_name }} </div>
                                <div> {{ checked_kvk_info }} </div>
                            </div>
                        </b-card>
                    </app-row-left-label>

                    <app-row-left-label v-if="!loading && status == 2" >            
                        <b-card no-body class="p-2 app-local-card-min-height app-card-warning">
                            <div class="app-info-card-label">
                                <label class="flabel">{{trans('wrong-number',256)}}</label>
                            </div>
                        </b-card>
                    </app-row-left-label>

                    <app-row-left-label v-if="!loading && status == 3" >            
                        <b-card no-body class="app-local-card-min-height app-card-light">
                            <div class="app-info-card-label">
                                <label class="flabel">{{trans('kvk-is-busy',234)}}</label>
                            </div>
                        </b-card>
                    </app-row-left-label>

                    <app-row-left-label v-if="!loading && status == 4" >            
                        <b-card no-body class="app-local-card-min-height app-card-warning">
                            <div class="app-info-card-label">
                                <label class="flabel">{{trans('kvk-limitation',256)}}</label>
                            </div>
                        </b-card>
                    </app-row-left-label>

                </b-col>
            </b-row>
            <app-row-left-label v-if="cItemData.client_data.id_location_data_main != null">
                <app-check-box v-model="cItemData.client_data.kvk_organization" :rightLabel="trans('kvk-same-as-organization', 236)" @change="getOrganizationKvk($event)" />
            </app-row-left-label>
        </b-form>
        </ValidationObserver>
</template>

<script>
import axios from "@axios";
import {ValidationObserver} from "vee-validate";

export default {
    components: {
        ValidationObserver
    },

    props: {
        kvk: {},
        clientName:{},
        required: { type: Boolean, default: false },
        idItem: {type: Number, default: 0},
        itemData: {type: Object}
    },

    created() {
        
        if(this.kvk.kvk_number != null && this.kvk.kvk_number.toString().length == 8 &&
           this.kvk.kvk_data.city != null && this.kvk.kvk_data.city.toString().length > 0 && 
           this.kvk.kvk_data.street != null && this.kvk.kvk_data.street.toString().length > 0 &&
           this.kvk.kvk_data.trade_name != null && this.kvk.kvk_data.trade_name.toString().length > 0){
            this.checked_kvk_info = this.kvk.kvk_data.street + ", " + this.kvk.kvk_data.city;
            this.checked_kvk_trade_name = this.kvk.kvk_data.trade_name;
            this.status = 1;
            this.basic_kvk = JSON.parse(JSON.stringify(this.kvk));
        }
        if(this.kvk.kvk_number != null && this.kvk.kvk_number.length != 0 && this.kvk.kvk_data.city == "" && this.kvk.kvk_data.street == "" && this.kvk.kvk_data.trade_name == ""){
            this.status = 2;
        }
        
    },

    mounted(){
        if(this.kvk.kvk_number != null && this.kvk.kvk_number.length != 0 && this.kvk.kvk_data.city == null && this.kvk.kvk_data.street == null && this.kvk.kvk_data.trade_name == null){
            this.checkKvk();
        }
    },

    computed: {
        cClientName:{
            get() { return this.clientName },
            set(value) { this.$emit('update:clientName', value) }
        },
        cKvk:{
            get() { return this.kvk },
            set(value) { this.$emit('update:kvk', value) }
        },
        cItemData:{
            get() { return this.itemData },
            set(value) { this.$emit('update:itemData', value) }
        },
    },
    
    data() {
        return {
            loading: false,
            status: 0,   
            checked_kvk_info: "",
            checked_kvk_trade_name: "",
            clients_ids: null,
            persons_ids: null,
            refresh:0,
            basic_kvk: null,


        };
    },

    methods: {
        checkKvk(){

            if(this.basic_kvk && this.basic_kvk.kvk_number == this.kvk.kvk_number)  {
                this.cKvk.kvk_number = this.basic_kvk.kvk_number;
                this.cKvk.kvk_data.kvk_number = this.basic_kvk.kvk_number;
                this.cKvk.kvk_data.city = this.basic_kvk.kvk_data.city;
                this.cKvk.kvk_data.street = this.basic_kvk.kvk_data.street;
                this.cKvk.kvk_data.trade_name = this.basic_kvk.kvk_data.trade_name;
                this.clients_ids = null
                this.clients_names = null
                this.checked_kvk_info = this.basic_kvk.kvk_data.street + ", " + this.basic_kvk.kvk_data.city;
                this.checked_kvk_trade_name = this.basic_kvk.kvk_data.trade_name;
                this.status = 1;
                return;
            }
            
            if(this.kvk.kvk_number === null || this.kvk.kvk_number === undefined || this.kvk.kvk_number.toString().length !== 8){
                this.status = 0;
                this.checked_kvk_info = "";
                this.checked_kvk_trade_name = "";
                this.cKvk.kvk_data.kvk_number = "";
                this.cKvk.kvk_data.city = "";
                this.cKvk.kvk_data.street = "";
                this.cKvk.kvk_data.trade_name = "";
                
                return;
            }

            this.loading = true;
            this.cKvk.kvk_data.kvk_number = "";
            this.cKvk.kvk_data.city = "";
            this.cKvk.kvk_data.street = "";
            this.cKvk.kvk_data.trade_name = "";

            this.$refs.observer.validate();
            
            axios
                .post("core/kvk/check",{
                    kvk_number: this.kvk.kvk_number,
                    refresh: 0,
                    id_item: this.idItem,
                    item_type: 2,
                    token: this.$route.params.token 
                }) 
                .then((response) => {
                    
                    if(response.data == -1){
                        this.checked_kvk_info = "";
                        this.checked_kvk_trade_name = "";
                        return this.status = 2;
                    }else if(response.data == -2){
                        this.checked_kvk_info = "";
                        this.checked_kvk_trade_name = "";
                        return this.status = 4;

                    }

                    

                    if(response.data.clients_ids != null || response.data.persons_ids != null){
                        this.clients_ids = response.data.clients_ids;
                        this.persons_ids = response.data.persons_ids;
                        //this.checked_kvk_info = "";
                        //this.checked_kvk_trade_name = "";

                        this.cKvk.kvk_number = response.data.kvk_number;
                        this.cKvk.kvk_data.kvk_number = response.data.kvk_number;
                        this.cKvk.kvk_data.city = response.data.city;
                        this.cKvk.kvk_data.street = response.data.street;
                        this.cKvk.kvk_data.trade_name = response.data.trade_name;
                        this.checked_kvk_info = this.kvk.kvk_data.street + ", " + this.kvk.kvk_data.city;
                        this.checked_kvk_trade_name = this.kvk.kvk_data.trade_name;

                        return this.status = 3;
                    }else{

                        this.cKvk.kvk_number = response.data.kvk_number;
                        this.cKvk.kvk_data.kvk_number = response.data.kvk_number;
                        this.cKvk.kvk_data.city = response.data.city;
                        this.cKvk.kvk_data.street = response.data.street;
                        this.cKvk.kvk_data.trade_name = response.data.trade_name;
                        this.checked_kvk_info = this.kvk.kvk_data.street + ", " + this.kvk.kvk_data.city;
                        this.checked_kvk_trade_name = this.kvk.kvk_data.trade_name;

                        this.status = 1;
                    }

                    
                })
                .catch((error) => {
                    this.loading = false;
                    console.error(`error during checking KVK: ${error}`);
                })
                .finally(() =>{
                    this.loading = false;
                });

        },

        changingKvk(){
            if(this.cKvk.kvk_number.length > 8){
                this.cKvk.kvk_number = this.cKvk.kvk_number.substring(0,8);
                this.refresh++;

                //check if mozilla firefox
                if(typeof InstallTrigger !== 'undefined'){
                    this.checkKvk();
                }
            }
        },

        getOrganizationKvk(value){
            if(value == '1'){
                this.loading = true;
                axios
                    .get("clients/data-wizard/getOrganizationKvk/" + this.$route.params.token, {
                        id_client_data_main: this.itemData.client_data.id_location_client,
                    })
                    .then((res) => {

                        this.cKvk.kvk_number = res.data.kvk_number;
                        this.cKvk.kvk_data = JSON.parse(res.data.kvk_data_main);
                        this.checked_kvk_info = JSON.parse(res.data.kvk_data_main).street + ", " + JSON.parse(res.data.kvk_data_main).city;
                        this.checked_kvk_trade_name = JSON.parse(res.data.kvk_data_main).trade_name

                    })
                    .catch((error) => {
                        console.error(`error during request: ${error}`);
                    })
                    .finally(() => {
                        this.loading = false;

                    }); 

            }
            this.refresh++;
        }


    },
};
</script>

<style scoped>
.app-local-card-min-height {
    min-height: 96px;
    padding: 1rem 1.5rem !important;
}

.app-local-spinner {
    width:16px; 
    height:16px; 
    margin-right:10px;
}
</style>
